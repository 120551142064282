/* You can add global styles to this file, and also import other style files */
@import './assets/scss/_variables.scss';

html {
  font-size: 14px;
  position: relative;
}

body {
  @include fontAcuminLight();
  margin: 0;
  background-color: #F2F2F2;
  scroll-behavior: smooth;
}

.inline-links {
  margin: 0 getSize(-16);

  &.padding-right {
    padding-right: getSize(32);
  }

  a, span.as-a {
    display: inline-block;
    padding-left: getSize(16);
    padding-right: getSize(16);
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

@each $s in $colors {
  .bg-#{nth($s, 1)} {
    background-color: nth($s, 2);
  }

  .text-#{nth($s, 1)} {
    color: nth($s, 2) !important;
  }
}

a, button {
  &.no-underline {
    text-decoration: none;
  }

  &.underline {
    text-decoration: none;
    padding-bottom: getSize(3);
    display: inline-block;
    border-bottom: getSize(2) solid $color_main;
    letter-spacing: 0;

    &.text-light-gray2 {
      border-color: $color_quote;
    }
  }
}

button.underline {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border-radius: 0;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
}

img.ng-lazyloaded {
  animation: fadein 1.3s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.room-carousel,
.offer-carousel {
  .owl-carousel.owl-theme {
    .owl-nav {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      padding: 0 2.25rem;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;

      @include md() {
        bottom: -18px;
        width: calc(4 * 135px + 200px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        max-width: 100%;
      }

      .owl-prev,
      .owl-next {
        margin: 0;
        padding: 0;
        background: none;
      }

      .owl-prev {
        transform: rotate(180deg);
      }

      & + .owl-dots {
        margin-top: 45px;
        max-width: calc(4 * 135px);
        margin-left: auto;
        margin-right: auto;

        @include md() {
          display: flex;
        }

        .owl-dot {
          flex-grow: 1;

          span {
            margin: 0;
            border-radius: 0;
            height: 2px;
            width: 100%;
            background: #707070;
            opacity: .2;
          }

          &.active {
            span {
              height: 4px;
              opacity: 1;
              position: relative;
              top: -1px
            }
          }
        }
      }
    }
  }
}

.loyalty-program-nav {
  .owl-carousel.owl-theme {
    .owl-nav {
      position: absolute;
      left: -35px;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% + 70px);
      height: 43px;
      z-index: -1;
      margin: 0;

      .owl-prev,
      .owl-next {
        margin: 0;
        padding: 0;
        background: none;
        position: absolute;
      }

      .owl-prev {
        transform: rotate(180deg);
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }
  }
}

#offers {
  .offer-carousel {
    .owl-stage {
      display: flex;
      justify-content: center;
    }
    .owl-item {
      max-width: 590px;
    }
  }
}


@import './assets/scss/_bootstrap.scss';
@import './assets/scss/_common.scss';
@import './assets/scss/_mixins.scss';
@import './assets/scss/_responsive.scss';
@import './assets/scss/_themes.scss';
@import './assets/scss/_typography.scss';
@import './assets/scss/reservations/_forms.scss';
@import './assets/scss/reservations/_common.scss';
@import './assets/scss/reservations/_typography.scss';
@import './assets/scss/reservations/_user-panel.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
