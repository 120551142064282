@import '_variables.scss';

.grey-square-after {
  display: block;
  position: relative;

  &::after {
    content: "";
    display: block;
    background: #e8e8e8;
    position: absolute;
    z-index: -1;
    width: 20%;
    height: 20%;
    bottom: -70px;
    top: auto;
    right: 0;
  }
}

.gray-box {
  position: relative;
  z-index: 2;

  &.smaller {
    &:before {
      height: 135%;
      top: -35%;
      left: 45.5%;
    }
  }

  &:before {
    content: '';
    background: #e8e8e8;
    width: 500%;
    height: getSize(1265);
    position: absolute;
    left: 43.5%;
    top: 0;
    z-index: -1;
  }
}

.img-bigger img {
  max-width: calc(100% + 38px);
}

.btn-bordered {
  border-radius: getSize(13);
  border: solid 1px $color_main;
  color: $color_main;
  font-weight: 500;
  padding: getSize(10) getSize(16);
  font-size: getSize(12);
  line-height: 1.36;
  text-decoration: none;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;

  &.bold-edges {
    border: solid 2px $color_main;
  }

  &.fatter {
    padding: getSize(12) getSize(16);
    border-width: 2px;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    border-color: #FFF;
    background: #FFF;
    color: #363636 !important;
    outline: 0;

    &:disabled {
      border-color: $color_main;
      cursor: not-allowed;
    }

    &.on-white {
      border-color: #F2F2F2;
      background: #F2F2F2;

      &:disabled {
        border-color: $color_main;
        background: #FFF;
      }
    }
  }

  i {
    padding-left: getSize(24);
  }
}

.btn-medium {
  min-width: 128px;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.content-center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-collapse {
  font-size: getSize(20);
  font-weight: 200;
  display: flex;
  align-items: center;

  &:before {
    margin-right: 33px;
    content: '';
    width: 28px;
    height: 14px;
    display: block;
    background: url('/assets/images/arrow-down.svg');
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-white {
  color: $color_fifth;

  &:hover {
    color: $color-second;
  }
}

.btn-dark-only {
  border-radius: getSize(8);
  background-color: #4f4f4f;
  color: #FFF;
  @include fontAcuminSemibold();
  transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease, border 0.3s ease;

  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.inactive {
    opacity: 0.25;
    background: #E6E6E6;
    color: $color_main;
    border-color: #E6E6E6;

    &:focus, &:active, &:hover {
      opacity: 0.5;
    }
  }
}

.btn-dark {
  border-radius: getSize(8);
  background-color: #a7a7a7;
  border-color:  #a7a7a7;
  font-size: getSize(24);
  line-height: 1.27;
  color: #FFF;
  @include fontAcuminSemibold();
  padding: getSize(12) getSize(12) getSize(15) getSize(12);
  transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease, border 0.3s ease;

  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.medium {
    font-size: getSize(20);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.regular {
    font-size: getSize(16);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.inactive {
    opacity: 0.25;
    background: #E6E6E6;
    color: $color_main;
    border-color: #E6E6E6;

    &:focus, &:active, &:hover {
      opacity: 0.5;
    }
  }
  .checked {

  }
}

.btn-outline-dark {
  border-radius: getSize(8);
  background-color: transparent;
  font-size: getSize(24);
  line-height: 1.27;
  color: #363636;
  @include fontAcuminSemibold();
  padding: getSize(12) getSize(12) getSize(15) getSize(12);
  transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease, border 0.3s ease;

  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.medium {
    font-size: getSize(20);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.regular {
    font-size: getSize(16);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.inactive {
    opacity: 0.25;
    background: #E6E6E6;
    color: $color_main;
    border-color: #E6E6E6;

    &:focus, &:active, &:hover {
      opacity: 0.5;
    }
  }
}

.btn-outline-offer {
  border-radius: getSize(8);
  background-color: #EDDDC6;
  font-size: getSize(24);
  line-height: 1.27;
  color: #5D5345;
  @include fontAcuminSemibold();
  padding: getSize(12) getSize(12) getSize(15) getSize(12);
  transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease, border 0.3s ease;
  border-color: #5D5345;


  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.medium {
    font-size: getSize(20);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.regular {
    font-size: getSize(16);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.inactive {
    opacity: 0.25;
    background: #E6E6E6;
    color: $color_main;
    border-color: #E6E6E6;

    &:focus, &:active, &:hover {
      opacity: 0.5;
    }
  }
}

.btn-offer {
  border-radius: getSize(8);
  background-color: #BE9D6D;
  font-size: getSize(24);
  line-height: 1.27;
  color: #FFF;
  @include fontAcuminSemibold();
  padding: getSize(12) getSize(12) getSize(15) getSize(12);
  transition: opacity 0.3s ease, color 0.3s ease, background 0.3s ease, border 0.3s ease;
  border-color: #BE9D6D;

  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &.medium {
    font-size: getSize(20);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.regular {
    font-size: getSize(16);
    padding: getSize(12) getSize(12) getSize(12) getSize(12);
  }

  &.inactive {
    opacity: 0.25;
    background: #E6E6E6;
    color: $color_main;
    border-color: #E6E6E6;

    &:focus, &:active, &:hover {
      opacity: 0.5;
    }
  }
}

.btn-arrow {
  border-radius: 0;
  padding-bottom: getSize(8);
  padding-right: getSize(24);
  padding-left: 0;
  border-bottom: 1px solid $color_main;
  text-decoration: none;
  position: relative;
  color: $color_main;
  transition: opacity ease 0.3s;
  @include fontAcuminSemibold();
  font-size: getSize(14);
  min-width: getSize(109);
  text-align: left;

  &:before {
    display: block;
    content: '';
    position: absolute;
    right: getSize(0.5 );
    bottom: getSize(-4);
    border: solid black;
    border-width: 0 1px 1px 0;
    padding: getSize(3);
    transform: rotate(-45deg);
    border-radius: getSize(1);
  }

  &:hover {
    opacity: 0.5;
    color: $color_main;
  }

  &.left {
    padding-left: getSize(24);
    padding-right: getSize(0);
    text-align: right;

    &:before {
      transform: rotate(135deg);
      right: auto;
      left: getSize(-1);
    }
  }
}

.link-underlined {
  font-size: getSize(12);
  font-weight: 600;
  letter-spacing: 0.11px;
  text-align: center;
  color: $color_main;

  &.btn {
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
      color: $color_main;
      text-decoration: none;
    }
  }
}

.btn-hover {
  color: $color_main;
  transition: opacity 0.2s ease;

  &:hover {
    color: $color_main;
    opacity: 0.7;
  }
}

.btn-small-underlined {
  font-size: getSize(12);
  font-weight: 600;
  padding-bottom: 8px;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: $color_main;
    bottom: 0;
    left: 0;
  }
}

.btn-link {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font-weight: 200;
  text-decoration: underline;

  &:hover {
    color: inherit;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
}

.btn-underlined {
  padding: 0;
  font-size: getSize(11);
  text-decoration: underline;
}

.btn-bordered.btn-white {
  border: solid 1px $color_fifth;
}

.btn-bordered.btn-dark-only {
  border: solid 1px #4f4f4f;
}

.container.wide {
  max-width: 1900px;
}

.btn-white-background {
  background: $color_fifth;
  border: none;
  color: $color_main;
  padding: 12px 16px;
  box-shadow: 0 4px 11px 0 rgba(140, 151, 165, 0.15);
  font-size: getSize(11);
  border-radius: 13px;

  span {
    transition: opacity ease 0.2s;
  }

  &:hover {
    opacity: 1;

    span {
      opacity: 0.8;
    }
  }
}

.before-text {
  position: relative;

  h6 {
    display: inline-block;
    content: '';
    position: absolute;
    font-size: getSize(120);
    line-height: 1.13;
    letter-spacing: 0.6px;
    color: #646464;
    transform: translateY(-75%);
    z-index: 1;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container.container-no-breakpoints {
  max-width: $max-width;
}

ul {
  margin: 0;

  &.list-dashed {
    list-style-type: none;

    & > li {
      //text-indent: -40px;
      position: relative;
      padding-left: 10px;

      &:before {
        content: "-";
        //text-indent: -40px;
        position: absolute;
        left: 0;
      }
    }
  }

  &.list-disc {
    list-style-type: disc;
  }
}

.ul-styled, .user-content-offer-wrapper ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
  color: $color-main;

  li:first-of-type {
    margin-top: 0;
  }

  li {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-top: 16px;
    font-size: getSize(14);
    line-height: 1.71;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: block;
      margin-top: getSize(8);
      background-color: #E8E8E8;
      position: absolute;
      left: 0;
    }
  }

  &.ul-light {

    li {
      &::before {
        background: #e8e8e8;
      }
    }
  }

  &.ul-white {
    color: $color_fifth;
  }
}

.top-indent {
  margin-top: -84px;
}

.custom-job-offer-padding {
  padding: 96px 106px;
}

.max-100 {
  max-width: 100%;
}

.xxl-max-width-wider {
  width: calc(100% + 12px);
}

.opened {
  .ngx-dropdown-container {
    .nsdicon-angle-down {
      transform: rotate(0deg);
    }
  }
}

.ngx-dropdown-container {
  .ngx-dropdown-button {
    border: 0px !important;
    min-height: 50px !important;
    outline: 0 !important;
    border-radius: 16px !important;
    padding: getSize(21) getSize(40) getSize(21) getSize(40) !important;
    @include fontAcuminMedium();
    font-weight: 500 !important;
  }

  .ngx-dropdown-list-container {
    margin-top: 4px;
    border: 0px !important;
    border-radius: 16px !important;
    box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.05) !important;
    padding: 0 getSize(24) !important;

    ul {
      &.selected-items {
        li {
          background-color: rgba(#A2A2A2, 0.5) !important;
          color: white !important;
          border-radius: 40px !important;
          padding: getSize(13) getSize(16) !important;

          span {
            &.nsdicon-close {
              padding-left: 0;
              margin-left: 10px;
            }

            padding-left: 14px;
          }
        }
      }

      li {
        color: $color_main !important;
        padding: getSize(10) getSize(16) !important;
        font-weight: 300 !important;
        outline: 0 !important;
      }
    }
  }

  .nsdicon-angle-down {
    background-image: url('/assets/images/arrow-up.svg');
    background-size: 100% auto;
    width: 22px;
    height: 12px;
    transform: rotate(180deg);
    top: 5px;
    transition: all ease 0.5s;

    &:before {
      content: '' !important;
      display: none !important;
    }
  }

  .nsdicon-close {
    background-image: url('/assets/images/close-icon2.svg');
    background-size: 100% auto;
    width: 11px;
    height: 11px;
    display: inline-block;

    &:before {
      content: '' !important;
      display: none !important;
    }
  }
}

.gray-opacity {
  color: $color_main;
  opacity: 0.5;
}

.opacity-half {
  opacity: 0.5;
}

.opacity-quarter {
  opacity: 0.25;
}

.video-wrapper {
  position: relative;
  cursor: pointer;

  &:hover {
    .video-player {
      opacity: 0.7;
      visibility: visible;
    }
  }

  &.hide-player {
    cursor: auto;

    .video-player {
      opacity: 0;
      visibility: hidden;
    }
  }

  .video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 15px), -50%);
    width: 100px;
    opacity: 0.5;
    transition: 0.4s;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.absolute-right-element {
  position: absolute;
  padding-right: 0;
  right: 0;
}

.opacity-link {
  text-decoration: none;
  display: block;

  &:hover {
    opacity: 0.8;
  }
}

.bg-light-gray {
  background-color: $color_second;
}

.bg-white {
  background-color: $color_fifth;
}

.bg-gold {
  background-color: rgba(239, 231, 220, 0.4);
}

.bg-gray {
  background-color: $color_lightgray;
}

.bg-darker-grey {
  background-color: $color_bg_gray;
}

.btn.btn-bordered.disabled {
  cursor: default;
  opacity: 0.4;

  &:hover {
    border-color: inherit;
    background: inherit;
    color: inherit;
  }
}

.header {
  .grayHeader {
    color: #646464;
    @include fontAcuminThin();
  }
}

.arrow {
  display: inline-block;
  width: getSize(17);
  height: getSize(9);
  background-image: url('/assets/images/reservations/icons/arrow_down.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  transition: transform ease 0.3s;

  &.arrow-down {
    transform: rotate(180deg);
  }
}

.number-badge {
  display: inline-block;
  background-color: $color_second;
  border-radius: getSize(8);
  padding: getSize(6) getSize(16) getSize(11) getSize(16);
  font-size: getSize(24);
  line-height: 0.9;
  @include fontAcuminMedium();
}

.bg-none {
  background: none;
}

.duration-form-group .ng-select .ng-select-container {
  color: #333;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $color_main;
  min-height: 30px;
  align-items: center;
  max-width: 150px;
}

.duration-form-group .ng-dropdown-panel {
  max-width: 150px;
}

.duration-form-group .ng-select .ng-arrow-wrapper {
  //position: absolute;
  //left: 0;
}

@include xxl() {
  .offset-xxl-0 {
    margin-left: 0;
  }
}

@include md-only() {
  .chiefs-container.container {
    padding: 0 $padding-bootstrap;
  }
}

@include sm-max() {
  .chiefs-container.container {
    max-width: 540px;
  }

  .img-bigger img {
    margin-left: -19px;
  }
}

@include md-max() {
  .btn-underlined {
    font-size: getSize(11, 'rem', 14);
  }
}

@include xl-only {
  .reservations-square-gray .grey-square {
    width: 55% !important;
  }
}

@include xl-max() {
  .custom-job-offer-padding {
    padding: getSize(64);
  }

  .xxl-max-width-wider {
    width: 100%;
  }
}

.text-main {
  .c-scrolldown {
    .c-line {
      background-image: linear-gradient(to bottom, rgba($color_main, 1) 50%, rgba($color_main, 0) 50%) !important;
    }
  }
}

.very-small-text, div.very-small-text p {
  font-size: getSize(12);
  font-weight: 500;
  line-height: 1.71;
  color: $color_gray;

  &.weight-300 {
    font-weight: 300;
  }

  strong {
    font-weight: 600;
  }

  ul {
    margin: 10px 0;
  }

  ul + br {
    display: none;
  }
}

.inside-links a {
  text-decoration: none;
  font-weight: 500;
}

@include md-max() {
  .before-text h6 {
    font-size: getSize(100);
  }

  .mobile-padding {
    padding: 0 2.25rem;
  }

  .custom-job-offer-padding {
    padding: 48px;
  }

  .out-mobile-padding {
    margin-left: -1.4rem !important;
    margin-right: -1.4rem !important;
  }

  .full-out-mobile-padding {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .ngx-dropdown-container {
    .ngx-dropdown-button {
      padding: getSize(21) getSize(24) getSize(21) getSize(24) !important;
    }

    .ngx-dropdown-list-container {
      ul {
        &.selected-items {
          li {
            padding: getSize(10) getSize(14) !important;

            span {
              &.nsdicon-close {
                margin-left: 6px;
              }

              padding-left: 10px;
            }
          }
        }

        li {
          padding: getSize(10) getSize(0) !important;
        }
      }
    }
  }

  .nsdicon-angle-down {
    width: 18px !important;
    height: 10px !important;
  }
}

@include lg-max {
  .reservations-square-gray .grey-square {
    width: 60% !important;
    left: 0 !important;
    top: 25% !important;
  }
}

@include sm-max() {
  .p-responsive {
    font-size: getSize(17);
  }

  .custom-job-offer-padding {
    padding: 40px 32px;
  }

  .absolute-right-element {
    position: static;
  }

  .padding-sm-zero {
    padding: 0;
  }
}

@include xs-only() {
  .before-text h6 {
    font-size: getSize(60);
  }

  .btn-sm-wrapper > * {
    display: block;
  }

  .btn-sm-wrapper > a {
    display: inline-block;
  }
}

@include ratio125-xs() {
  .btn-bordered {
    border-width: 1px; // 0.75px;

    &.fatter {
      border-width: 2px; // 1.5px;
    }
  }
}

@include ratio150-xs() {
  .btn-bordered {
    border-width: 1px; // 0.5px;

    &.fatter {
      border-width: 2px; // 1px;
    }
  }
}

@include xl-only {
  .reservations-square-gray .grey-square {
    width: 55% !important;
  }
}


@include lg-max {
  .reservations-square-gray .grey-square {
    width: 60% !important;
    left: 0 !important;
    top: 25% !important;
  }
}
