@import '../_variables.scss';

.form {
  .form-group {
    border-radius: 0 0 getSize(8) getSize(8);
    position: relative;
    margin: 0;

    &::after {
      //content: " ";
      display: block;
      width: calc(100% - 2px);
      height: 10px;
      background: transparent;
      border-radius: 0 0 getSize(9) getSize(9);
      margin-top: -8px;
      z-index: 0;
      position: absolute;
      left: 1px;
      transition: 0.2s;
    }

    label, input, textarea {
      display: block;
    }

    input::-ms-clear, input::-ms-reveal {
      display: none;
    }

    .form-input {
      width: 100%;
      background: #fff;
      border: solid 1px $color_border_gray;
      border-radius: getSize(8);
      transition: 0.2s border-bottom ease;
      //position: relative;
      //z-index: 1;

      &:focus-within {
        border-bottom: solid 1px transparent;
        box-shadow: 0 2px 0 0 #0ab80a;
      }
    }

    &.on-dark {
      .form-input {
        border: 1px solid $color_fifth;
      }

      &::after {
        width: 100%;
        left: 0;
      }

      label {
        font-size: getSize(12);
        line-height: 1.27;
        letter-spacing: 2.4px;
        color: $color_main;
      }
    }

    input, textarea {
      outline: none;
      border: none;
      background: none;
      width: 100%;
      font-family: acumin-pro-wide, sans-serif;
      font-size: getSize(14);
      font-weight: 300;
      line-height: 1.71;
      letter-spacing: 0.13px;
      color: $color_main;
    }

    input[type="text"], textarea, input[type="password"]  {
      border-radius: getSize(8);
      padding: getSize(9) getSize(13);
    }

    ng-select {
      .ng-value {
        font-size: getSize(14);
      }
    }

    &.disabled {
      input:-webkit-autofill  {
        box-shadow: 0 0 0px 1000px #f9f9f9 inset;
      }

      .ng-select-container:hover {
        box-shadow: none !important;
      }
    }

    input:-webkit-autofill {
      background: none;
      box-shadow: 0 0 0px 1000px $color_fifth inset;
    }

    textarea {
      height: getSize(220);
      resize: none;
    }

    label {
      font-size: getSize(11);
      letter-spacing: 1.53px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      color: #959595;
      text-transform: uppercase;
      margin-bottom: getSize(10);

      &.label-round {
        margin-bottom: 0;
      }
    }

    &:focus-within {
      &::after {
        background: #0ab80a;
      }
    }

    &.disabled .form-input {
      background-color: #f9f9f9;
    }

    .field-name-secondary {
      font-size: getSize(11);
      letter-spacing: 0.5px;
      opacity: 0.6;
      margin-left: getSize(8);
    }

    &.password-group .form-input {
      padding-right: getSize(13);
    }
  }

  .validation-msg {
    position: absolute;
    font-size: getSize(11);
    margin-top: getSize(5);
    color: $color_red;
    right: 0;
    display: none;

    &.summary {
      display: block;

      &:empty {
        display: none;
      }
    }
  }

  .invalid {
    &::after {
      background: $color_red;
    }

    .form-input {
      border-bottom: solid 1px transparent;
      box-shadow: 0 2px 0 0 $color_red;
    }

    .validation-msg {
      display: block;
    }
  }

  .invalid:focus-within {
    &::after {
      background: $color_red;
    }

    .form-input {
      border-bottom: solid 1px transparent;
      box-shadow: 0 2px 0 0 $color_red;
    }

    .validation-msg {
      display: block;
    }
  }

  p {
    a {
      font-size: inherit;
      opacity: 1;
    }
  }

  .input-radio-container {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.form.bigger {
  .form-group {
    .form-input {
      border: solid 1px #e6e6e6;
    }

    label {
      font-size: getSize(12);
    }

    input[type="text"], textarea, input[type="password"] {
      padding: getSize(11) getSize(15);
    }
  }

  .checkbox-group {
    .form-checkbox-text {
      font-size: getSize(16);
      line-height: 1.27;
    }

    .form-checkbox-check {
      width: 22px;
      height: 22px;
      border-radius: 8px;
      border: solid 2px $color_quote;

      &::after {
        left: 8px;
        top: 4px;
        width: 6px;
        height: 11px;
      }
    }
  }
}

.validation-msg-container {
  width: 100%;
  position: relative;

  p {
    font-size: getSize(12);
    font-weight: 600;
    width: 100%;

    &.error {
      color: $color_red;
    }

    &.success {
      color: $color_green;
    }
  }

  &.summary-top {
    text-align: center;
  
    p {
      position: absolute;
      top: getSize(-7);
    }
  }
}

.facebook-button {
  a, button {
    background: $color_facebook;
    color: $color_fifth;
  }

  a:hover, button:hover {
    background: rgba(24, 119, 242, 0.7);
    color: $color_fifth;
  }
}

.checkbox-group .validation-msg {
  margin-top: getSize(4);
}

.google-button {
  a, button {
    background: $color_fifth;
    border: solid 1px $color_gray;

    &:hover {
      border: 1px solid $color_fifth;
    }
  }

  &.on-white:hover {
    a, button {
      border-color: $color_second;
      background: $color_second;
    }
  }
}

.facebook-button, .google-button {
  &:hover a, &:hover button {
    opacity: 1;
  }

  a, button {
    padding: getSize(16);
    border-radius: getSize(16);
    text-decoration: none;
    font-weight: 600;
    font-size: getSize(12);
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  }

  span {
    width: 100%;
    text-align: center;
  }

  img {
    margin-left: getSize(40);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.small {
    img {
      margin-left: getSize(10);
    }

    @include xl-max() {
      img {
        margin-left: 0;
        position: static;
        transform: none;
      }

      span {
        padding: 0 getSize(5);
      }
    }
  }
}

.login-break {
  font-size: getSize(11);
  color: $color_gray;
  text-align: center;
  position: relative;

  .line {
    display: block;
    width: 100%;
    background-color: $color_gray;
    height: 1px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
  }

  .text {
    position: relative;
    background: $color_second;
    padding: 0 24px;
    text-transform: uppercase;
    letter-spacing: 1.53px;
  }

  &.on-white .text {
    background-color: $color_fifth;
  }
}

.form-header {
  font-size: getSize(22);
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: -0.77px;
  color: $color_main;
  opacity: 0.5;
}

.line-break {
  display: block;
  width: 100%;
  background: $color_lightgray;
  height: 1px;
}


.validation-msg-above {
  color: $color_red;
  font-size: getSize(14);
}

@include sm-max() {
  .facebook-button, .google-button {
    a {
      padding: getSize(10) getSize(16);
    }

    span {
      padding-left: getSize(5);
      padding-right: getSize(5);
    }

    img {
      margin-left: 0;
      position: static;
      transform: none;
    }
  }

  .form .validation-msg {
    margin-top: getSize(4);
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /** Focus dla IE */
  input:focus {
    box-shadow: 0px 0px 2px 2px rgba(#9ecaed, 0.7);
  }
}

.reservation-step {
  padding-bottom: 89px;

  @include md-max() {
    padding-bottom: 78px;
  }
}

.isSideSummaryVisible {
  .reservation-step {
    @include md-max() {
      padding-bottom: 147px;
    }
  }
}