.reservation-fragments {
  display: flex;
  overflow: hidden;
}

.reservation-fragment {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .reservation-fragment-element {
    border-bottom: 1px solid #CFCFCF;
    background: $color-second;
    padding: 24px;
    border-right: 1px solid #CFCFCF;
    font-size: getSize(18);
    font-weight: 600;
    color: $color_main;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &:last-of-type .reservation-fragment-element {
    border-right: none;
    border-radius: 0 8px 8px 0;
  }

  &:first-of-type .reservation-fragment-element {
    border-radius: 8px 0 0 8px;
  }

  &.price {
    .reservation-fragment-element {
      background: #fff;
    }

    .price-main {
      font-size: getSize(24);
      padding-right: 4px;
    }

    .price-currency {
      font-size: getSize(16);
    }
  }
}

@media (min-width: 992px) and (max-width: 1040px) {
  .owl-carousel {
    width: 95%;
    margin: 0 auto;
  }
}

@include xs-only() {
  .loyalty-program-nav {
    .owl-stage-outer {
      margin-right: -31.5px;
    }

    .owl-nav {
      display: none;
    }
  }

  .mobile-slider {
    .item {
      padding: 0 !important;
    }

    .gallery-arrows {
      .gallery-arrow {
        background-color: $color_fifth !important;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        top: calc(255px / 2) !important;

        img {
          content: url('/assets/images/forward_arrow_mobile.svg');
        }

        &.arrow-right {
          img {
            position: relative;
            left: 2px;
          }
        }
      }
    }
  }

  .discover-relax-and-beauty-slider {
    .row {
      margin-left: -31px;
      margin-right: -31px;

      .col-12 {
        padding-left: 0;
        padding-right: 0;
      }

      .gallery-arrows .gallery-arrow {
        top: calc(255px / 2) !important;
      }

      .text-offer-container {
        max-width: 100%;

        .text-offer-wrapper {
          margin-left: 0;

          .image-wrapper {
            width: 100%;
            max-width: 100%;

            &:before {
              padding-bottom: 0%;
              height: 255px;
            }
          }

          .text-wrapper {
            background: transparent;
            width: 100%;
            max-width: 100%;
            left: 0;
            margin-top: 0;
            position: static;
            transform: none;

            .stickers {
              .sticker {
                transform: none !important;
              }
            }
          }
        }
      }
    }
  }

  .discover-relax-and-beauty-treatments {
    .col-12 {
      padding-left: 0;
      padding-right: 0;
    }

    .text-offer-container {
      max-width: 100% !important;

      .text-offer-wrapper {
        margin-left: 0;

        .image-wrapper {
          width: 100%;
          max-width: 90%;

          &:before {
            padding-bottom: 0%;
            height: 255px;
          }
        }

        .text-wrapper {
          background: transparent;
          width: 100%;
          max-width: 100%;
          left: 0;
          margin-top: 0;
          padding-left: 0;
          padding-right: 0;
          min-height: auto !important;
          position: static;
          transform: none;

          .stickers {
            .sticker {
              transform: none !important;
            }
          }
        }
      }
    }
  }

  .restore-vital-forces-slider {
    app-item-slider > .row {
      margin-left: -31px;
      margin-right: -31px;

      .col-12,
      .mobile-slider {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .row {
      margin-left: 0;
      margin-right: 0;

      .gallery-arrows .gallery-arrow {
        top: calc(255px / 2) !important;
      }

      .img-wrapper-container {
        .img-wrapper {
          width: 100%;

          .img-left {
            display: none;
          }

          .img-right {
            width: 100% !important;
            max-width: 100% !important;

            img {
              object-fit: cover;
              height: 255px;
            }
          }
        }
      }
    }
  }

  .facilities,
  .offers {
    .text-offer-container {
      .text-offer-wrapper {
        margin-left: 0;

        .image-wrapper {
          width: 100%;
          margin-left: auto;
          margin-right: auto;

          &:before {
            padding-bottom: 80%;
          }
        }

        .text-wrapper {
          left: 50%;
          transform: translateX(-50%);

          .actions {
            text-align: center;
          }
        }
      }
    }
  }
}
