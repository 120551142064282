@import '../variables';

.btn-lined {
  font-size: getSize(12);
  padding: 0 0 8px 0;
  position: relative;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px; 
    background: $color_main;
    transition: 0.2s opacity ease;
  }

  &.btn-white {
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px; 
      background: $color_fifth;
      margin-top: 8px;
      position: absolute;
    }
  }
}

.btn.btn-black {
  background-color: $color_main;
  color: $color_fifth;

  &:hover {
    background: #6e6e6e;
    border-color: #6e6e6e;
    color: $color_fifth !important;
  }
}

.bordered-element {
  border-radius: getSize(8);
}

.copy-toast {
  position: relative;
  z-index: 5;

  span {
    position: absolute;
    background: rgba($color_main, 0.8);
    color: $color_fifth;
    padding: getSize(8) getSize(16);
    top: getSize(16);
    border-radius: getSize(8);
  }
}
