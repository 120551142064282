@import '_variables.scss';

html, body {
  color: $color-main;
}

h1, .h1 {
  @include fontAcuminExtraLight();
  font-size: getSize(80);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.08;
  letter-spacing: 0.4px;
}

h2, .h2 {
  @include fontAcuminLight();
  font-size: getSize(60);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.27;
  letter-spacing: 0.54px;

  &.bigger {
    @include fontAcuminExtraLight();
    font-size: getSize(80);
  }

  &.small {
    @include fontAcuminExtraLight();
    font-size: getSize(48);
    line-height: 1.33;
    letter-spacing: 0.43px;

    &.light {
      @include fontAcuminLight();
    }
  }

  &.smaller {
    @include fontAcuminLight();
    font-size: getSize(40);
    line-height: 1.27;
  }
}

h3, .h3 {
  @include fontAcuminLight();
  font-size: getSize(32);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  letter-spacing: 0.16px;

  &.smaller {
    font-size: getSize(24);
    line-height: 1.41;
    letter-spacing: 0.11px;
  }
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

h4, .h4 {
  @include fontAcuminExtraLight();
  font-size: getSize(20);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  letter-spacing: 0.1px;

  &.high {
    font-size: getSize(22);
    line-height: 1.36;
    letter-spacing: 0.11px;
  }

  &.regular {
    @include fontAcuminRegular();
  }

  &.semibold-hind {
    @include fontHindSemibold();
  }

  &.medium {
    @include fontAcuminMedium();
  }

  &.semibold {
    @include fontAcuminSemibold();
  }
}

h5, .h5 {
  @include fontAcuminLight();
  font-size: getSize(16);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  letter-spacing: 0.1px;

  &.medium {
    @include fontAcuminMedium();
  }

  &.semibold {
    @include fontAcuminSemibold();
  }
}

h6, .h6 {
  @include fontAcuminLight();
  font-size: getSize(14);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.25;
  letter-spacing: 0.1px;

  &.strong {
    @include fontAcuminBold();
  }

  &.semibold {
    @include fontAcuminSemibold();
  }

  &.hind-semibold {
    @include fontHindSemibold();
  }
}

.the-biggest {
  font-size: getSize(120);
  line-height: 0.9;
  letter-spacing: 0.6px;
  font-weight: 200;
}

.hind, .hind > * {
  @include fontHindLight();
}

p {
  @include fontAcuminExtraLight();
  font-size: getSize(22);
  color: inherit;
  margin: 0;
  padding: 0;
  line-height: 1.36;

  &.bigger {
    font-size: getSize(28);
  }

  &.medium {
    font-size: getSize(24);
  }

  &.regular {
    @include fontAcuminLight();
    font-size: getSize(16);
  }

  &.regular-semibold {
    @include fontAcuminSemibold();
    font-size: getSize(16);
  }

  &.regular-medium {
    @include fontAcuminMedium();
    font-size: getSize(16);
  }

  &.small-semibold {
    @include fontAcuminSemibold();
    font-size: getSize(14);
  }

  &.small-medium {
    @include fontAcuminMedium();
    font-size: getSize(14);
  }

  &.small-light {
    @include fontAcuminLight();
    font-size: getSize(14);
  }

  &.small-extra-light {
    font-size: getSize(16);
  }

  &.regular-hind {
    @include fontHindLight();
    font-size: getSize(16);
    line-height: 1.5;
    letter-spacing: 0.8px;

    &.semibold {
      @include fontHindSemibold();
    }
  }

  &.small, &.small * {
    @include fontHindLight();
    font-size: getSize(16);
    line-height: 1.71;
    letter-spacing: 1px;

    &.font-600 {
      font-weight: 600;
    }
  }

  &.tiny, &.tiny * {
    @include fontHindLight();
    font-size: getSize(14);
    line-height: 1.71;
    letter-spacing: 0.7px;

    &.semibold {
      @include fontHindSemibold();
    }
  }
}

a {
  @include fontAcuminExtraLight();
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  transition: color ease 0.3s, opacity ease 0.3s;

  &:hover {
    text-decoration: none;
  }

  &.color-transition {
    &:hover {
      color: #6d6d6d;
      opacity: 1;
    }
  }
}

.text-hind-semibold {
  @include fontHindSemibold()
}

.text-hind-light {
  @include fontHindLight();
}

.text-acumin-bold {
  @include fontAcuminBold();
}

.text-acumin-semibold {
  @include fontAcuminSemibold();
}

.text-acumin-medium {
  @include fontAcuminMedium();
}

.text-acumin-light {
  @include fontAcuminLight();
}

.text-acumin-thin {
  @include fontAcuminThin();
}

.font-14 {
  font-size: getSize(14);
}

.heading {
  font-size: getSize(60);
}

.white-text-indent {
  color: $color_fifth;
  font-weight: 200;
  line-height: 1.13;
  margin-left: 20%;
  position: absolute;
  bottom: 74px;
  display: block;
  padding: 0 40px 0 51px;
  text-indent: -51px;
  width: 80%;
}

.bold-grey-text {
  font-size: getSize(22);
  font-weight: 600;
  line-height: 1.36;
  letter-spacing: 0.11px;
  color: $color-quote;
}

.small-text, .small-text p {
  font-size: getSize(16);
  line-height: 1.71;
  letter-spacing: 0.7px;
  color: $color_main;
  font-weight: 300;
}

.text-color-gray-var {
  color: $color_gray;
}

.light-subtitle {
  font-size: getSize(15);
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.14px;
  color: $color_quote;
}

.smaller-text {
  font-size: getSize(16);
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.14px;
  color: $color_main;
}

.dark-bold-small {
  font-size: getSize(15);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.14px;
  color: $color_main;
}

.text-gray-main {
  color: $color_main;
}

.text-very-light-gray {
  color: $color-quote;
}

.font-600 {
  font-weight: 600;
}

.b-600 b {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.blueText {
  &, p, h1, h2, h3, h4, h5, h6 {
    color: $color_blue;
  }
}

.opacity-half {
  opacity: 0.5;
}

.vertical-align-middle {
  vertical-align: middle;
}

// .opening-hours-header {
//   color: $color-main;
//   opacity: 0.5;
//   font-size: getSize(60);
//   line-height: 1.27;
// }

// @include lg-only() {
//   .heading {
//     font-size: getSize(50);
//     margin-top: 0;
//   }
// }

@include xl-max() {
  .the-biggest {
    font-size: getSize(80);
  }
}

@include lg-max() {
  .heading {
    font-size: getSize(45);
  }
}

@include lg-max() {
  .opening-hours .h1 {
    font-size: 64px;
  }
}

@include md-max() {
  h2, .h2 {
    font-size: getSize(44);
  }

  .opening-hours .h1 {
    font-size: getSize(60);
  }

  .h3.responsive {
    font-size: getSize(24);
  }
}

@include sm-max() {
  h2, .h2 {
    font-size: getSize(30, 'rem', 14);

    &.bigger {
      font-size: 2.5rem;
      line-height: 1.2;
    }
  }

  .the-biggest {
    font-size: getSize(60);
  }

  .white-text-indent {
    bottom: 24px;
    text-indent: 0;
    padding: 0;
    margin-left: 32px;
    font-size: 42px;
  }

  .opening-hours .h1 {
    font-size: getSize(44);
  }

  .sm-font-22 {
    font-size: getSize(24);
  }
}

@include xs-only() {
  h1, .h1 {
    font-size: getSize(60, 'rem', 14);
  }

  .heading {
    font-size: getSize(35);
  }

  .opening-hours .h1 {
    font-size: getSize(30);
  }

  p.xs-small, h3.xs-small {
    font-size: getSize(20);
  }

  .h3.responsive {
    font-size: getSize(22);
  }
}

@include ratio125-xs() {
  html.use-ratio {
    font-size: 12px;
  }
}

@include ratio125-lg() {
  html.use-ratio {
    font-size: 14px;
  }
}

@include ratio150-xs() {
  html.use-ratio {
    font-size: 10px;
  }
}

@include ratio150-md() {
  html.use-ratio {
    font-size: 12px;
  }
}
