@import '_variables.scss';

body.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

// header active
.nav-container {
  &.active {
    box-shadow: 0px 33px 89px 0px #74878D26;

    &.opened {
      box-shadow: 0px 33px 89px 0px #74878Dff;

      .background-blurred {
        transition: none;
        background-color: transparent;
        backdrop-filter: none;
        transform: translate3d(0, 0, 0) translateZ(0);
      }

      .nav-content {
        color: white;

        .c-scrolldown {
          .c-line {
            background-image: linear-gradient(to bottom, #FFF 50%, rgba(255, 255, 255, 0) 50%);
          }
        }

        .tear-line {
          span {
            background-color: #FFF;
          }
        }
      }

      .nav-logo {
        svg  {
          path {
            fill: white;
          }
        }
      }
    }

    .nav-logo {
      svg  {
        path {
          fill: $color_main;
        }
      }
    }

    .background-blurred {
      opacity: 1;
      background-color: rgba(247,247,247,0.69);
    }

    .nav-content {
      color: $color_main;

      .c-scrolldown {
        .c-line {
          background-image: linear-gradient(to bottom, $color_main 50%, rgba(255, 255, 255, 0) 50%);
        }
      }

      .tear-line {
        span {
          background-color: $color_main;
        }
      }
    }

    .hamburger-inner {
      background-color: $color_main;

      &:after, &:before {
        background-color: $color_main;
      }
    }
  }

  &.dark-menu {
    &.opened {
      .nav-content {
        .tear-line {
          span {
            background-color: #FFF;
          }
        }
      }
    }

    .nav-content {
      color: $color_main;

      .c-scrolldown {
        .c-line {
          background-image: linear-gradient(to bottom, $color_main 50%, rgba(255, 255, 255, 0) 50%);
        }
      }

      .tear-line {
        span {
          background-color: #363636;
        }
      }
    }

    .hamburger-inner {
      background-color: $color_main;

      &:after, &:before {
        background-color: $color_main;
      }
    }

    .nav-logo {
      svg  {
        path {
          fill: $color_main;
        }
      }
    }
  }

  &.dark-menu.opened {
    .nav-content {
      color: $color_fifth;

      .c-scrolldown {
        .c-line {
          background-image: linear-gradient(to bottom, $color_fifth 50%, rgba(255, 255, 255, 0) 50%);
        }
      }
    }

    .hamburger-inner {
      background-color: $color_fifth;

      &:after, &:before {
        background-color: $color_fifth;
      }
    }

    .nav-logo {
      svg  {
        path {
          fill: $color_fifth;
        }
      }
    }
  }
}

// header extended
.fixed-top {
  transform: translateY(-100%);
  transition: transform 0.5s ease-in;
}

.fixed-top.extended {
  transform: translateY(0);
}

.fixed-top {
  .nav-container {
    transition: background ease 0.5s, opacity ease 0.5s, box-shadow ease 0.5s;
  }

  .nav-container.active {
    box-shadow: 0px 33px 89px 0px #74878d00;
    opacity: 0;
  }

  &.extended {
    .nav-container.active {
      box-shadow: 0px 33px 89px 0px #74878D26;
      opacity: 1;
    }

    .nav-container.active.opened {
      box-shadow: 0 33px 89px 0 #74878d00;
    }
  }
}
