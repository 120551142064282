@import '_variables.scss';

.row {
  margin-right: -12px;
  margin-left: -12px;
}

.container, .container-fluid, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-right: 12px;
  padding-left: 12px;
}

//.h-100 {
//  height: 100% !important;
//}
//
//.fixed-top {
//  position: fixed;
//  top: 0;
//  right: 0;
//  left: 0;
//  z-index: 1030;
//}

a {
  &:hover {
    color: inherit;
    opacity: 0.8;
  }
}

.form-control {
  &.gray {
    background-color: rgba(#a2a2a2, 0.2);
    color: rgba(#363636, 1);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(#363636, 0.4);;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba(#363636, 0.4);;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: rgba(#363636, 0.4);;
    }
  }
}

@media (min-width: 1368px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1368px;
  }
}

@include xxl() {
  @for $i from 1 through 12 {
    $val: calc(($i * 100)/12);

    .col-xxl-#{$i} {
      flex: 0 0 #{$val}#{'%'};
      max-width: #{$val}#{'%'};
    }

    .offset-xxl-#{$i} {
      margin-left: #{$val}#{'%'};
    }
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none !important;
  }
}
