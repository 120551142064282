$_base: 16;

$color_main: #363636;
$color_second: #F2F2F2;
$color_third: #DBDBDB;
$color_fourth: #000000;
$color_fifth: #FFFFFF;
$color_gray: #959595;
$color_lightgray: #E8E8E8;
$color_quote: #A2A2A2;
$color_btn_hover: #212529;
$color_blue: #8c97a5;
$color_dark_gray: #6f6f6f;
$color_border_gray: #d2d2d2;
$color_facebook: #1877f2;
$color_green: #63b566;
$color_red: #ff0000;
$color_bg_gray: #e6e6e6;
$color_loyalty_gray: #5B5B5B;

$screen-xs-min: 0;
$screen-xs-max: 575;
$screen-sm-min: 576;
$screen-md-min: 768;
$screen-lg-min: 992;
$screen-xl-min: 1200;
$screen-xxl-min: 1368;

// this value is also used in environment
$mobile-menu-max-width: 1139;
$mobile-menu-max-xl-width: 1600;

$max-width: 1440px;
$padding-mobile: 2.25rem;
$padding-bootstrap: 12px;

$spacings: ('zero', 0), ('centi', 8), ('deci', 16), ('deca', 24), ('hecto', 32), ('kilo', 40), ('mega', 48),
  ('giga', 64), ('tera', 72), ('peta', 96), ('exa', 120), ('zetta', 150), ('200', 200), ('yotta', 216), ('28', 28), ('12', 12), ('56', 56);

$colors: ('main', $color_main), ('second', $color_second), ('third', $color_third), ('fourth', $color_fourth),
  ('fifth', $color_fifth), ('gray', $color_gray), ('white', #FFF), ('light-gray', $color_quote), ('light-gray2', $color_quote),
  ('light-gray3', $color_lightgray), ('loyalty-gray', $color_loyalty_gray);

@function getSize( $size, $unit: 'rem', $base: $_base ) {
  @return #{calc($size/$base)}#{$unit};
}

@mixin xs {
  @media (min-width: #{$screen-xs-min}#{'px'}) {
    @content;
  }
}

@mixin xs-only {
  @media (max-width: #{$screen-xs-max}#{'px'}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}#{'px'}) {
    @content;
  }
}

@mixin sm-only {
  @media (min-width: #{$screen-sm-min}#{'px'}) and (max-width: #{$screen-md-min - 1}#{'px'}) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: #{$screen-md-min - 1}#{'px'}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}#{'px'}) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: #{$screen-md-min}#{'px'}) and (max-width: #{$screen-lg-min - 1}#{'px'}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$screen-lg-min - 1}#{'px'}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}#{'px'}) {
    @content;
  }
}

@mixin lg-only {
  @media (min-width: #{$screen-lg-min}#{'px'}) and (max-width: #{$screen-xl-min - 1}#{'px'}) {
    @content;
  }
}

@mixin lg-max {
  @media (max-width: #{$screen-xl-min - 1}#{'px'}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}#{'px'}) {
    @content;
  }
}

@mixin xl-only {
  @media (min-width: #{$screen-xl-min}#{'px'}) and (max-width: #{$screen-xxl-min - 1}#{'px'}) {
    @content;
  }
}

@mixin xl-max {
  @media (max-width: #{$screen-xxl-min - 1}#{'px'}) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$screen-xxl-min}#{'px'}) {
    @content;
  }
}

@mixin ratio125-xs {
  @include xs() {
    @media (-webkit-min-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4) {
      @content;
    }
  }
}

@mixin ratio125-sm {
  @include sm() {
    @media (-webkit-min-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4) {
      @content;
    }
  }
}

@mixin ratio125-md {
  @include md() {
    @media (-webkit-min-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4) {
      @content;
    }
  }
}

@mixin ratio125-lg {
  @include lg() {
    @media (-webkit-min-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4) {
      @content;
    }
  }
}

@mixin ratio150-xs {
  @include xs() {
    @media (min-resolution: 1.5dppx),
    (min-resolution: 144dpi),
    (-webkit-min-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3/2) {
      @content;
    }
  }
}

@mixin ratio150-sm {
  @include sm() {
    @media (min-resolution: 1.5dppx),
    (min-resolution: 144dpi),
    (-webkit-min-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3/2) {
      @content;
    }
  }
}

@mixin ratio150-md {
  @include md() {
    @media (min-resolution: 1.5dppx),
    (min-resolution: 144dpi),
    (-webkit-min-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3/2) {
      @content;
    }
  }
}

@mixin ratio150-lg {
  @include lg() {
    @media (min-resolution: 1.5dppx),
    (min-resolution: 144dpi),
    (-webkit-min-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3/2) {
      @content;
    }
  }
}

@mixin fontAcuminBold() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin fontAcuminExtraLight() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin fontAcuminLight() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin fontAcuminMedium() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin fontAcuminSemibold() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin fontAcuminThin() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin fontAcuminRegular() {
  font-family: acumin-pro-wide, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin fontHindLight() {
  font-family: hind, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin fontHindSemibold() {
  font-family: hind, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  src: url(/assets/fonts/OpenSans-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@mixin defineSpacings( $prefix, $base: $_base ) {
  @each $s in $spacings {
    .pt#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      padding-top: getSize(nth($s, 2), 'rem', $_base);
    }

    .pb#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      padding-bottom: getSize(nth($s, 2), 'rem', $_base);
    }

    .pl#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      padding-left: getSize(nth($s, 2), 'rem', $_base);
    }

    .pr#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      padding-right: getSize(nth($s, 2), 'rem', $_base);
    }

    .mt#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      margin-top: getSize(nth($s, 2), 'rem', $_base);
    }

    .mt-minus#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      margin-top: getSize(nth($s, 2) * -1, 'rem', $_base);
    }

    .mb#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      margin-bottom: getSize(nth($s, 2), 'rem', $_base);
    }

    .mr#{if($prefix, '-' + $prefix, null)}-#{nth($s, 1)} {
      margin-right: getSize(nth($s, 2), 'rem', $_base);
    }
  }
}
