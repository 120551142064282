@import '../variables';

.small-text {
  font-size: getSize(12);

  &.weight-300 {
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: 0.11px;
  }
}

.link-bold {
  font-size: getSize(12);
  color: $color_main;
  text-decoration: none;
  font-weight: 600;
}

.bold-bigger-text {
  font-size: getSize(18);
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.16px;
  color: $color_main;
}

.text-light-gray-x {
  font-size: getSize(16);
  font-weight: 300;
  line-height: 1.38;
  letter-spacing: 0.14px;
  color: $color_quote;
}

.text-bold {
  font-size: getSize(16);
  font-weight: 600;
  line-height: 1.27;
  color: $color_main;
}

.text-uppercase {
  font-size: getSize(12);
  font-weight: 300;
  line-height: 1.27;
  letter-spacing: 2.4px;
  color: $color_main;
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.line-height-1-27 {
  font-size: getSize(16);
  line-height: 1.27;
}

.weight-200 {
  font-weight: 200;
}

.weight-300 {
  font-weight: 300;
}

.weight-500 {
  font-weight: 500;
}

.text-green {
  font-size: getSize(14);
  font-weight: 600;
  line-height: 1.27;
  color: $color_green;
}

.text-light-gray-color {
  color: $color_quote;
}

.small-gray {
  font-size: getSize(14);
  font-weight: 300;
  line-height: 1.27;
  color: $color_quote;

  &.active {
    color: $color_main;
  }
}
